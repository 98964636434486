<template>
  <div class="sidebar">
    <div class="switchBtn">
      <p v-if="$store.state.isCollapse" @click="$store.commit('switch_menu')"><i class="iconfont icon-zhankai"
                                                                                 style="color: #999"></i></p>
      <p v-else @click="$store.commit('switch_menu')"><i class="iconfont icon-shouqi" style="color: #999"></i></p>
    </div>

    <el-menu :default-active="onRoutes" class="el-menu-vertical" unique-opened router :collapse="menuShow"
             background-color="#242f42" text-color="#f9f9f9" active-text-color="#f90" style="height: 100%">
            <span v-for="item in items" :key="item.index">
                <template v-if="item.subs">
                    <el-submenu :index="item.index">
                        <template slot="title">
                            <i class="iconfont" :class="item.icon"></i> {{ item.title }}
                        </template>
                        <el-menu-item v-for="(subItem,i) in item.subs" :key="i" :index="subItem.index">
                            {{ subItem.title }}
                        </el-menu-item>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index">
                        <i class="iconfont" :class="item.icon"></i> {{ item.title }}
                    </el-menu-item>
                </template>
            </span>
    </el-menu>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        //isCollapse: true,
        items: [
          {
            icon: 'icon-kongzhitai',
            index: '/main',
            title: '控制台'
          },
          {
            icon: 'icon-zhuji',
            index: '/device',
            title: '主机管理'
          },
          {
            icon: 'icon-dashuju',
            index: '/app',
            title: '应用管理'
          },
          {
            icon: 'icon-APPguanli',
            index: '/soft',
            title: '软件管理'
          },
          {
            icon: 'icon-jiqun',
            index: '/group',
            title: '集群管理'
          },
          {
            icon: 'icon-safe',
            index: '/safe',
            title: '安全设备'
          },
          {
            icon: 'icon-jiaohuanji2',
            index: '/network',
            title: '网络设备'
          },
          {
            icon: 'icon-shexiangtou',
            index: '/other',
            title: '其他设备'
          },
          {
            icon: 'icon-jigui',
            index: '/room',
            title: '机房与机柜'
          },
          {
            icon: 'icon-yonghuguanli',
            index: '/manager',
            title: '管理者',
          },
          {
            icon: 'icon-anquan',
            index: '/report',
            title: '安全覆盖度',
          },
          {
            icon: 'icon-lanmuguanli',
            index: '/admin',
            title: '用户管理',
          },
          {
            icon: 'icon-dapingfuwu',
            index: '/log',
            title: '操作日志',
          },
        ]
      }
    },
    mounted() {
      // let width = window.screen.availWidth;
      // console.log(width);
      // if (width < 560) {
      //   this.$store.state.isCollapse = true;
      //   this.$store.commit('switch_menu')
      // }
    },
    computed: {
      menuShow() {
        return this.$store.state.isCollapse;
      },
      onRoutes() {
        let path = this.$route.path
        let pathArr = path.split('/')
        let routeName = pathArr[1]
        return '/' + routeName
        // return this.$route.path.replace('/', '')
      }
    },
    created() {
      //this.getMod();
    },
    methods: {}
  }
</script>

<style scoped>
  .sidebar {
    display: block;
    position: absolute;
    width: 160px;
    left: 0;
    top: 50px;
    bottom: 0;
  }

  .sidebar .switchBtn {
    width: 100%;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    background: #242f42;
    border-top: 1px solid hsla(0, 0%, 100%, .1);
  }

  .sidebar .switchBtn p {
    color: #f9f9f9;
  }

  .switchBtn p i.icon-shouqi {
    padding-left: 70px;
  }

  .switchBtn p i.icon-zhankai {
    padding-left: 20px;
  }

  .switchBtn ul {
    height: 100%;
  }


  .el-menu {
    border-top: 1px solid hsla(0, 0%, 100%, .1);
  }

  .iconfont {
    margin-right: 10px;
    font-size: 16px;
    color: #fff;
  }

  .el-menu-vertical:not(.el-menu--collapse) {
    width: 160px;
    min-height: 400px;
  }

  .el-menu--collapse {
    width: 50px;
  }

  .el-menu-item {
    height: 40px;
    line-height: 40px;
  }
</style>
