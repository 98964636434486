<template>
  <div class="header">
    <div class="logo">
      拨雾<sup>{{version}}</sup>
    </div>
    <div class="user-info">
      <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                  <i class="iconfont icon-profile-male"></i> {{username}}<i
                      class="el-icon-arrow-down el-icon--right"></i>
              </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="editpass">修改密码</el-dropdown-item>
          <el-dropdown-item command="logout">安全退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogEditpassFormVisible">
      <el-form ref="editpassForm" :model="editpassForm" :rules="rules" :label-width="formLabelWidth"
               @submit.prevent="submitForm('editpassForm')">
        <el-form-item label="旧密码" prop="password">
          <el-input v-model="editpassForm.password" type="password" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newpassword">
          <el-input v-model="editpassForm.newpassword" type="password" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="password_confirm">
          <el-input v-model="editpassForm.password_confirm" type="password" auto-complete="off"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="dialogEditpassFormVisible = false">取 消</el-button>
          <el-button type="primary" native-type="submit" @click.prevent="submitForm('editpassForm')">{{loading ? '提交中' :
            '确 定'}}
          </el-button>
        </div>
      </el-form>

    </el-dialog>

  </div>
</template>

<script>

  export default {
    data() {
      return {
        version: process.env.VUE_APP_VERSION,
        loading: false,
        formLabelWidth: '120px',
        dialogEditpassFormVisible: false,
        dialogAbout: false,
        editpassForm: {
          password: '',
          newpassword: '',
          password_confirm: ''
        },
        rules: {
          password: [
            {required: true, message: '请输入原密码', trigger: 'change'}
          ],
          newpassword: [
            {required: true, message: '请输入新密码', trigger: 'change'}
          ],
          password_confirm: [
            {required: true, message: '请输入确认密码', trigger: 'change'}
          ]
        }
      }
    },
    computed: {
      username() {
        let username = sessionStorage.getItem('uname')
        return username
      }
    },
    created() {

    },
    methods: {
      handleCommand(command) {
        if (command === 'logout') {
          this.$post(this.$api.Logout).then(res => {
            if (res.code === 0) {
              sessionStorage.removeItem('uname');
              sessionStorage.removeItem('access-token');
              this.$router.push('/login')
            } else {
              this.$message.error(res.message)
            }
          })
        } else if (command === 'editpass') {
          this.dialogEditpassFormVisible = true;
        }
      },
      submitForm(formName) {
        // const self = this
        if (this.editpassForm.newpassword !== this.editpassForm.password_confirm) {
          this.$message.error('两次新密码输入不一致');
          return false;
        }

        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            let formData = this.editpassForm
            // var params = new URLSearchParams();
            // params.append('oldpass', this.editpassForm.oldpass)
            // params.append('newpass', this.editpassForm.newpass)
            this.$post(this.$api.EditPassword, formData)
                .then((res) => {
                  if (res.code === 0) {
                    this.loading = false
                    this.$message({
                      message: '修改成功！请重新登录',
                      type: 'success'
                    })
                    sessionStorage.removeItem('uname')
                    sessionStorage.removeItem('access-token');
                    this.$router.push('/login')
                  } else {
                    this.loading = false
                    this.$message.error(res.msg)
                  }
                });

          } else {
            console.log('error submit!!')
            // return false
          }
        })
      }
    }
  }
</script>
<style scoped>
  .header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    font-size: 22px;
    line-height: 40px;
    color: #fff;
    background: #242f42;
  }

  .header .logo {
    float: left;
    width: 140px;
    padding-left: 10px;
    text-align: center;
  }

  .header .logo sup {
    margin-left: 10px;
    color: #999;
    font-size: 14px;
  }

  .top-menu {
    display: inline-block;
    padding: 0px 10px;
    position: relative;
  }

  .user-info {
    float: right;
    padding-right: 50px;
    font-size: 14px;
    color: #fff;
    line-height: 48px;
  }

  .user-info a {
    color: #f9f9f9;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .user-info .el-dropdown-link {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    color: #fff;
    cursor: pointer;
    vertical-align: middle;
  }

  .user-info .user-logo {
    position: absolute;
    left: 0;
    top: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .el-dropdown-menu__item {
    text-align: center;
  }

  .dialog-footer {
    margin-top: 40px;
    text-align: right;
  }


</style>
